import React from 'react'
import { graphql } from 'gatsby'
import Seo from '../components/utils/seo'

import ProjectSinglePageComponent from '../components/page/projectSinglePageComponent'

const ProjectSinglePage = ({ data }) => {
    return <ProjectSinglePageComponent _dataProjectSingle={data.postQuery} />
}
export default ProjectSinglePage

export const Head = ({ data }) => (
    <Seo
        _title={data.postQuery.title}
        _description={data.shareQuery.workShare.ogDescription || null}
        _ogImage={data.shareQuery.workShare.ogImage || null}
    />
)

export const pageQuery = graphql`
    query ProjectSinglePage($id: String) {
        postQuery: wpPost(id: { eq: $id }) {
            title
            id
            slug
            date(formatString: "YMMDDHHmmss")
            worksContents {
                roleField {
                    bausUrl
                    directInput {
                        role
                    }
                    relLink {
                        title
                        url
                    }
                }
                noLength
                videoLength {
                    hour
                    minutes
                    seconds
                }
                projectColor
                textColor
                contentsRepeaterField {
                    subTitle
                    contentField {
                        mediaType
                        vimeoId
                        youtubeId
                        isAutoPlay
                        showVideoLinkUrl
                        videoRatio {
                            width
                            height
                        }
                        videoPlaceholder {
                            localFile {
                                childImageSharp {
                                    gatsbyImageData(
                                        formats: [AUTO, WEBP]
                                        breakpoints: [686, 1568, 1736, 1920]
                                        width: 1920
                                        outputPixelDensities: 2
                                    )
                                }
                            }
                        }
                        textEn
                        textJp
                        imageSlider {
                            planeImage {
                                localFile {
                                    childImageSharp {
                                        gatsbyImageData(
                                            formats: [AUTO, WEBP]
                                            breakpoints: [750, 1568, 1800, 1920]
                                            width: 1920
                                            outputPixelDensities: 2
                                        )
                                    }
                                }
                            }
                        }
                        imageList {
                            imageType
                            vimeoGif
                            planeImage {
                                localFile {
                                    childImageSharp {
                                        gatsbyImageData(
                                            formats: [AUTO, WEBP]
                                            breakpoints: [750, 1568, 1800, 1920]
                                            width: 1920
                                            outputPixelDensities: 2
                                        )
                                    }
                                }
                            }
                            gifImage {
                                localFile {
                                    publicURL
                                }
                                width
                                height
                            }
                            video {
                                localFile {
                                    publicURL
                                }
                            }
                            vimeoId
                            youtubeId
                            showVideoLinkUrl
                            isAutoPlay
                            videoRatio {
                                width
                                height
                            }
                            videoPlaceholder {
                                localFile {
                                    childImageSharp {
                                        gatsbyImageData(
                                            formats: [AUTO, WEBP]
                                            breakpoints: [686, 1568, 1736, 1920]
                                            width: 1920
                                            outputPixelDensities: 2
                                        )
                                    }
                                }
                            }
                        }
                        imageListWithSpace
                        imageListHalf {
                            imageType
                            vimeoGif
                            planeImage {
                                localFile {
                                    childImageSharp {
                                        gatsbyImageData(
                                            formats: [AUTO, WEBP]
                                            breakpoints: [750, 1800]
                                            width: 1800
                                            outputPixelDensities: 2
                                        )
                                    }
                                }
                            }
                            gifImage {
                                localFile {
                                    publicURL
                                }
                                width
                                height
                            }
                            video {
                                localFile {
                                    publicURL
                                }
                            }
                            vimeoId
                            youtubeId
                            showVideoLinkUrl
                            isAutoPlay
                            videoRatio {
                                width
                                height
                            }
                            videoPlaceholder {
                                localFile {
                                    childImageSharp {
                                        gatsbyImageData(
                                            formats: [AUTO, WEBP]
                                            breakpoints: [686, 1568, 1736, 1920]
                                            width: 1920
                                            outputPixelDensities: 2
                                        )
                                    }
                                }
                            }
                        }
                        imageListOneThird {
                            imageType
                            vimeoGif
                            planeImage {
                                localFile {
                                    childImageSharp {
                                        gatsbyImageData(
                                            formats: [AUTO, WEBP]
                                            breakpoints: [750, 1800]
                                            width: 1800
                                            outputPixelDensities: 2
                                        )
                                    }
                                }
                            }
                            gifImage {
                                localFile {
                                    publicURL
                                }
                                width
                                height
                            }
                            video {
                                localFile {
                                    publicURL
                                }
                            }
                            vimeoId
                            youtubeId
                            showVideoLinkUrl
                            isAutoPlay
                            videoRatio {
                                width
                                height
                            }
                            videoPlaceholder {
                                localFile {
                                    childImageSharp {
                                        gatsbyImageData(
                                            formats: [AUTO, WEBP]
                                            breakpoints: [686, 1568, 1736, 1920]
                                            width: 1920
                                            outputPixelDensities: 2
                                        )
                                    }
                                }
                            }
                        }
                    }
                }
            }
            worksThumbnail {
                thumbnailGif {
                    localFile {
                        publicURL
                    }
                }
            }
        }
        shareQuery: wpPost(id: { eq: $id }) {
            workShare {
                ogImage {
                    localFile {
                        publicURL
                    }
                }
                ogDescription
            }
        }
    }
`
