import React from 'react'
import PropTypes from 'prop-types'
import * as styles from './style.module.sass'

import ProjectItemSingleTitleSection from '../../ui/projectItemTitleSection'
import ProjectSingleContent from '../../model/projectSingleContent'

const ProjectSinglePageComponent = ({ _dataProjectSingle }) => {
    return (
        <article
            className={styles.project_single}
            style={{ backgroundColor: _dataProjectSingle.worksContents.projectColor }}
        >
            <div>
                <ProjectItemSingleTitleSection
                    _arrayType="date"
                    _title={_dataProjectSingle.title}
                    _noLength={_dataProjectSingle.worksContents.noLength}
                    _hour={_dataProjectSingle.worksContents.videoLength.hour}
                    _minutes={_dataProjectSingle.worksContents.videoLength.minutes}
                    _seconds={_dataProjectSingle.worksContents.videoLength.seconds}
                    _date={_dataProjectSingle.date}
                    _gifSrc={_dataProjectSingle.worksThumbnail.thumbnailGif.localFile.publicURL}
                    _showGif
                    _isHovered
                    _isOpen
                    _textColor={_dataProjectSingle.worksContents.textColor}
                />
            </div>
            <div className={styles.project_single__content}>
                <div className={styles.project_single__content_container}>
                    <ProjectSingleContent _dataProjectItem={_dataProjectSingle} _closeFunc={null} />
                </div>
            </div>
        </article>
    )
}

export default ProjectSinglePageComponent

ProjectSinglePageComponent.propTypes = {
    _dataProjectSingle: PropTypes.shape({
        title: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
        worksContents: PropTypes.shape({
            noLength: PropTypes.bool,
            videoLength: PropTypes.shape({
                hour: PropTypes.number,
                minutes: PropTypes.number,
                seconds: PropTypes.number,
            }).isRequired,
            roleField: PropTypes.shape({
                bausUrl: PropTypes.string,
                directInput: PropTypes.arrayOf(
                    PropTypes.shape({
                        role: PropTypes.string,
                    })
                ),
                relLink: PropTypes.shape({
                    title: PropTypes.string,
                    url: PropTypes.string,
                }),
            }),
            projectColor: PropTypes.string.isRequired,
            textColor: PropTypes.string,
            contentsRepeaterField: PropTypes.arrayOf(
                PropTypes.shape({
                    subTitle: PropTypes.string,
                    contentField: PropTypes.arrayOf(
                        PropTypes.shape({
                            mediaType: PropTypes.string.isRequired,
                            vimeoId: PropTypes.string,
                            youtubeId: PropTypes.string,
                            isAutoPlay: PropTypes.bool,
                            showVideoLinkUrl: PropTypes.string,
                            videoRatio: PropTypes.shape({
                                width: PropTypes.number,
                                height: PropTypes.number,
                            }),
                            videoPlaceholder: PropTypes.shape({
                                localFile: PropTypes.shape({
                                    childImageSharp: PropTypes.shape({
                                        gatsbyImageData: PropTypes.oneOfType([
                                            PropTypes.shape({}),
                                            PropTypes.arrayOf(PropTypes.shape({})),
                                        ]),
                                    }),
                                }),
                            }),
                            textEn: PropTypes.string,
                            textJp: PropTypes.string,
                            imageSlider: PropTypes.arrayOf(
                                PropTypes.shape({
                                    planeImage: PropTypes.shape({
                                        localFile: PropTypes.shape({
                                            childImageSharp: PropTypes.shape({
                                                gatsbyImageData: PropTypes.oneOfType([
                                                    PropTypes.shape({}),
                                                    PropTypes.arrayOf(PropTypes.shape({})),
                                                ]),
                                            }),
                                        }),
                                    }),
                                }).isRequired
                            ),
                            imageListWithSpace: PropTypes.bool,
                            imageList: PropTypes.arrayOf(
                                PropTypes.shape({
                                    imageType: PropTypes.string.isRequired,
                                    vimeoGif: PropTypes.string,
                                    planeImage: PropTypes.shape({
                                        localFile: PropTypes.shape({
                                            childImageSharp: PropTypes.shape({
                                                gatsbyImageData: PropTypes.oneOfType([
                                                    PropTypes.shape({}),
                                                    PropTypes.arrayOf(PropTypes.shape({})),
                                                ]),
                                            }),
                                        }),
                                    }),
                                    gifImage: PropTypes.shape({
                                        localFile: PropTypes.shape({
                                            publicURL: PropTypes.string.isRequired,
                                        }),
                                        width: PropTypes.number.isRequired,
                                        height: PropTypes.number.isRequired,
                                    }),
                                    video: PropTypes.shape({
                                        localFile: PropTypes.shape({
                                            publicURL: PropTypes.string.isRequired,
                                        }),
                                    }),
                                    vimeoId: PropTypes.string,
                                    youtubeId: PropTypes.string,
                                    isAutoPlay: PropTypes.bool,
                                    showVideoLinkUrl: PropTypes.string,
                                    videoRatio: PropTypes.shape({
                                        width: PropTypes.number,
                                        height: PropTypes.number,
                                    }),
                                    videoPlaceholder: PropTypes.shape({
                                        localFile: PropTypes.shape({
                                            childImageSharp: PropTypes.shape({
                                                gatsbyImageData: PropTypes.oneOfType([
                                                    PropTypes.shape({}),
                                                    PropTypes.arrayOf(PropTypes.shape({})),
                                                ]),
                                            }),
                                        }),
                                    }),
                                }).isRequired
                            ),
                            imageListHalf: PropTypes.arrayOf(
                                PropTypes.shape({
                                    imageType: PropTypes.string.isRequired,
                                    vimeoGif: PropTypes.string,
                                    planeImage: PropTypes.shape({
                                        localFile: PropTypes.shape({
                                            childImageSharp: PropTypes.shape({
                                                gatsbyImageData: PropTypes.oneOfType([
                                                    PropTypes.shape({}),
                                                    PropTypes.arrayOf(PropTypes.shape({})),
                                                ]),
                                            }),
                                        }),
                                    }),
                                    gifImage: PropTypes.shape({
                                        localFile: PropTypes.shape({
                                            publicURL: PropTypes.string.isRequired,
                                        }),
                                        width: PropTypes.number.isRequired,
                                        height: PropTypes.number.isRequired,
                                    }),
                                    video: PropTypes.shape({
                                        localFile: PropTypes.shape({
                                            publicURL: PropTypes.string.isRequired,
                                        }),
                                    }),
                                    vimeoId: PropTypes.string,
                                    youtubeId: PropTypes.string,
                                    isAutoPlay: PropTypes.bool,
                                    showVideoLinkUrl: PropTypes.string,
                                    videoRatio: PropTypes.shape({
                                        width: PropTypes.number,
                                        height: PropTypes.number,
                                    }),
                                    videoPlaceholder: PropTypes.shape({
                                        localFile: PropTypes.shape({
                                            childImageSharp: PropTypes.shape({
                                                gatsbyImageData: PropTypes.oneOfType([
                                                    PropTypes.shape({}),
                                                    PropTypes.arrayOf(PropTypes.shape({})),
                                                ]),
                                            }),
                                        }),
                                    }),
                                }).isRequired
                            ),
                            imageListOneThird: PropTypes.arrayOf(
                                PropTypes.shape({
                                    imageType: PropTypes.string.isRequired,
                                    vimeoGif: PropTypes.string,
                                    planeImage: PropTypes.shape({
                                        localFile: PropTypes.shape({
                                            childImageSharp: PropTypes.shape({
                                                gatsbyImageData: PropTypes.oneOfType([
                                                    PropTypes.shape({}),
                                                    PropTypes.arrayOf(PropTypes.shape({})),
                                                ]),
                                            }),
                                        }),
                                    }),
                                    gifImage: PropTypes.shape({
                                        localFile: PropTypes.shape({
                                            publicURL: PropTypes.string.isRequired,
                                        }),
                                        width: PropTypes.number.isRequired,
                                        height: PropTypes.number.isRequired,
                                    }),
                                    video: PropTypes.shape({
                                        localFile: PropTypes.shape({
                                            publicURL: PropTypes.string.isRequired,
                                        }),
                                    }),
                                    vimeoId: PropTypes.string,
                                    youtubeId: PropTypes.string,
                                    isAutoPlay: PropTypes.bool,
                                    showVideoLinkUrl: PropTypes.string,
                                    videoRatio: PropTypes.shape({
                                        width: PropTypes.number,
                                        height: PropTypes.number,
                                    }),
                                    videoPlaceholder: PropTypes.shape({
                                        localFile: PropTypes.shape({
                                            childImageSharp: PropTypes.shape({
                                                gatsbyImageData: PropTypes.oneOfType([
                                                    PropTypes.shape({}),
                                                    PropTypes.arrayOf(PropTypes.shape({})),
                                                ]),
                                            }),
                                        }),
                                    }),
                                }).isRequired
                            ),
                        }).isRequired
                    ),
                }).isRequired
            ),
        }).isRequired,
        worksThumbnail: PropTypes.shape({
            thumbnailGif: PropTypes.shape({
                localFile: PropTypes.shape({
                    publicURL: PropTypes.string.isRequired,
                }),
            }),
        }).isRequired,
    }).isRequired,
}
